.fill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-grow: 1;
}

.overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-charcoal-alpha-15);
}

.loadingContainer {
  position: relative;
}

.extraLarge {
  width: 44px;
  height: 44px;
}

.large {
  width: 40px;
  height: 40px;
}

.medium {
  width: 32px;
  height: 32px;
}

.small {
  width: 24px;
  height: 24px;
}

.extraSmall {
  width: 16px;
  height: 16px;
}

.top {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
}

.bottom {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  left: 0;
}

.bottom path {
  fill: transparent;
}

.top path {
  fill: transparent;
  animation: loading infinite ease-out 1.4s;
}

@keyframes loading {
  0% {
    stroke-dashoffset: 210;
  }

  40% {
    stroke-dashoffset: 109;
  }

  50% {
    stroke-dashoffset: 109;
  }

  90% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.link {
  color: inherit;
}
